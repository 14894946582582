module.exports = [{
      plugin: require('/tmp/72cefc1d/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/72cefc1d/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-unwrap-images"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1170,"linkImagesToOriginal":false,"quality":75,"withWebp":false}},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-copy-linked-files"}],"remarkPlugins":[null],"defaultLayouts":{"default":"/tmp/72cefc1d/node_modules/gatsby-theme-carbon/src/templates/Default.js","home":"/tmp/72cefc1d/node_modules/gatsby-theme-carbon/src/templates/Homepage.js"}},
    },{
      plugin: require('/tmp/72cefc1d/node_modules/gatsby-theme-carbon/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/72cefc1d/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
