export default {
  grey: {
    0: '#2B2B2B',
    1: '#707373',
    2: '#A7ABA9',
    3: '#D0D2CF',
    4: '#EBEBE9',
    5: '#F9F9F8',
    6: '#FFFFFF',
  },
  primary: {
    0: '#2B0000',
    1: '#500000',
    2: '#8F0C07',
    3: '#BD2A21',
    4: '#DD5C51',
    5: '#F2A29A',
    6: '#FFF3F2',
  },
  secondary: {
    0: '#002E57',
    1: '#003C71',
    2: '#0057A3',
    3: '#1173C9',
    4: '#4499E3',
    5: '#93C7F4',
    6: '#F2F9FF',
  },
};
