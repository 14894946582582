// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/72cefc1d/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-images-fam-js": () => import("/tmp/72cefc1d/src/pages/about/images/fam.js" /* webpackChunkName: "component---src-pages-about-images-fam-js" */),
  "component---src-pages-about-index-mdx": () => import("/tmp/72cefc1d/src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-contact-index-mdx": () => import("/tmp/72cefc1d/src/pages/contact/index.mdx" /* webpackChunkName: "component---src-pages-contact-index-mdx" */),
  "component---src-pages-index-mdx": () => import("/tmp/72cefc1d/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-patient-resources-index-mdx": () => import("/tmp/72cefc1d/src/pages/patient-resources/index.mdx" /* webpackChunkName: "component---src-pages-patient-resources-index-mdx" */),
  "component---src-pages-practice-expertise-ankle-and-foot-mdx": () => import("/tmp/72cefc1d/src/pages/practice-expertise/ankle-and-foot.mdx" /* webpackChunkName: "component---src-pages-practice-expertise-ankle-and-foot-mdx" */),
  "component---src-pages-practice-expertise-elbow-mdx": () => import("/tmp/72cefc1d/src/pages/practice-expertise/elbow.mdx" /* webpackChunkName: "component---src-pages-practice-expertise-elbow-mdx" */),
  "component---src-pages-practice-expertise-knee-mdx": () => import("/tmp/72cefc1d/src/pages/practice-expertise/knee.mdx" /* webpackChunkName: "component---src-pages-practice-expertise-knee-mdx" */),
  "component---src-pages-practice-expertise-overview-mdx": () => import("/tmp/72cefc1d/src/pages/practice-expertise/overview.mdx" /* webpackChunkName: "component---src-pages-practice-expertise-overview-mdx" */),
  "component---src-pages-practice-expertise-shoulder-mdx": () => import("/tmp/72cefc1d/src/pages/practice-expertise/shoulder.mdx" /* webpackChunkName: "component---src-pages-practice-expertise-shoulder-mdx" */)
}

