import React from 'react';
import ResourceLinks from 'gatsby-theme-carbon/src/components/LeftNav/ResourceLinks';

const links = [
  { title: 'Ortho Illustrated', href: 'https://www.orthoillustrated.com/' },
  { title: 'OrthoInfo', href: 'https://orthoinfo.org/' },
  {
    title: 'AAOS',
    href: 'https://aaos.org/Default.aspx?ssopc=1',
  },
];

const CustomResources = () => <ResourceLinks links={links} />;

export default CustomResources;
