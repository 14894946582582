import React from 'react';
import { HomepageBanner, HomepageCallout } from 'gatsby-theme-carbon';
import './homepage.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'carbon-components-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { css } from '@emotion/core';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import colors from '../../data/colors';

// Component to be shadowed

const FirstLeftText = () => (
  <p>
    Think → <strong>Performance</strong>
  </p>
);

const FirstRightText = () => (
  <>
    <h3
      css={({ typeStyles }) => ({
        ...typeStyles.expressiveHeading06,
        marginBottom: '0.5rem',
      })}
    >
      Practice expertise
    </h3>
    <p>
      Dr. Rowland is an orthopedic surgeon specializing in arthroscopic and open
      surgeries of the upper and lower extremities. He has a dedicated clinical
      staff and assistants to aid in delivering high-quality and expert care.
    </p>
    <br />
    <a
      style={{ textDecoration: 'none' }}
      href="https://centexsportsmedicine.televox.west.com/office-info/appointment-request"
    >
      <Button
        css={css(`
        background-color: ${colors.primary[4]};
        font-size: 16px;
        font-weight: 600;
        &:hover {
          background-color: ${colors.primary[3]};
        }
      `)}
      >
        Book an appointment →
      </Button>
    </a>
  </>
);

const SecondLeftText = () => <p>Community focused, patient&nbsp;driven.</p>;

const SecondRightText = () => (
  <>
    <h3
      css={({ typeStyles }) => ({
        ...typeStyles.expressiveHeading06,
        marginBottom: '0.5rem',
      })}
    >
      Practice locations
    </h3>
    <p>
      As a part of Central Texas Sports Medicine & Orthopaedics Dr. Rowland
      performs surgery at The Physicians Centre, Baylor Scott & White, College
      Station Medical Center, and CHI St. Joseph's Regional Medical Center.
      <a
        style={{ marginTop: '1rem', display: 'block', color: 'white' }}
        css={({ typeStyles }) => typeStyles.bodyShort02}
        href="http://www.centexsportsmedicine.com/"
      >
        Central Texas Sports Medicine →
      </a>
    </p>
  </>
);

const customProps = {
  Banner: <HomepageBanner />,
  FirstCallout: (
    <HomepageCallout leftText={FirstLeftText} rightText={FirstRightText} />
  ),
  SecondCallout: (
    <HomepageCallout
      leftText={SecondLeftText}
      rightText={SecondRightText}
      color={colors.primary[6]}
      backgroundColor={colors.primary[1]}
    />
  ),
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;
