import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = () => (
  <>
    <p>
      Dr. Rowland is an orthopedic surgeon specializing in arthroscopic and open
      surgeries.
    </p>
    <br />
    <p>
      He has a dedicated clinical staff and assistants to aid in delivering
      high-quality care for the Bryan-College Station community.
    </p>
    <br />
    <p>
      Website by <a href="https://vincepic.one">VPP</a>.
    </p>
  </>
);

const links = {
  firstCol: [
    {
      href: 'https://www.sportsmed.org/aossmimis',
      linkText: 'Orthopaedic Sports Medicine Society',
    },
    { href: 'aoassn.org', linkText: 'American Ortho Association' },
    { href: 'https://orthoinfo.org/', linkText: 'OrthoInfo' },
  ],
  secondCol: [
    { href: '/practice-expertise/overview', linkText: 'Practice Expertise' },
    { href: '/patient-resources', linkText: 'Patient Resources' },
    { href: '/about', linkText: 'About' },
    { href: '/contact', linkText: 'Contact' },
  ],
};

const Logo = () => (
  <svg
    style={{ minWidth: '250px', maxWidth: '33%', marginBottom: '1.5rem' }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 413.55 80.97"
  >
    <title>Grand Rowland M.D.</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M102.93,31.39h-.16c-.87,3.35-4.54,5.88-10,5.88-8.83,0-15-6.56-15-18.64S83.88,0,93.12,0c6.45,0,10.79,3.2,13.11,7.9l-3.56,2.17a9.94,9.94,0,0,0-9.55-6.2c-6.35,0-10.69,4.7-10.69,11.82v5.84c0,7.12,4.24,11.87,11.1,11.87,5,0,9.19-2.58,9.19-7.9V21.94h-8V18.12H107V36.65h-4Z"
          fill="#f9f9f8"
        />
        <path
          d="M122.91,36.65h-4.34V.62h14.35C139.22.62,143,4.28,143,10.84c0,5.27-2.53,8.83-7.23,9.91L144,36.65h-4.9l-7.8-15.49h-8.41Zm10-19.2c3.41,0,5.42-1.86,5.42-5.16V9.6c0-3.3-2-5.16-5.42-5.16h-10v13Z"
          fill="#f9f9f8"
        />
        <path
          d="M177.27,36.65,173.65,26H159.1l-3.62,10.63h-4.39l12.6-36h5.52l12.6,36Zm-10.79-32h-.26L160.13,22.2h12.44Z"
          fill="#f9f9f8"
        />
        <path
          d="M199.62,14.66l-4.34-8h-.15v30H190.9V.62h5l13.17,22,4.33,8h.16V.62h4.23v36h-5Z"
          fill="#f9f9f8"
        />
        <path
          d="M243.91,4.44V36.65h-4.33V4.44H228.12V.62h27.25V4.44Z"
          fill="#f9f9f8"
        />
        <path
          d="M4.34,80.35H0v-36H14.35c6.3,0,10.07,3.66,10.07,10.22,0,5.26-2.53,8.83-7.23,9.91l8.26,15.9H20.54L12.75,64.86H4.34Zm10-19.2c3.41,0,5.42-1.86,5.42-5.17V53.3c0-3.3-2-5.16-5.42-5.16h-10v13Z"
          fill="#f9f9f8"
        />
        <path
          d="M33.81,62.33c0-12.08,6-18.63,15.28-18.63s15.28,6.55,15.28,18.63S58.39,81,49.09,81,33.81,74.41,33.81,62.33Zm25.92,3v-6c0-7.12-4.39-11.77-10.64-11.77S38.46,52.22,38.46,59.34v6c0,7.12,4.39,11.77,10.63,11.77S59.73,72.45,59.73,65.33Z"
          fill="#f9f9f8"
        />
        <path
          d="M80.28,80.35l-8.78-36h4.59L80,61.66l3,13.47h.1l3.36-13.47L91,44.32h5l4.38,17.34,3.36,13.42h.15l3.1-13.42L111,44.32h4.44l-9.24,36h-4.8l-4.7-18-3.3-13.21h-.11l-3.4,13.21-4.8,18Z"
          fill="#f9f9f8"
        />
        <path d="M124.36,80.35v-36h4.34V76.53H144v3.82Z" fill="#f9f9f8" />
        <path
          d="M177.17,80.35l-3.61-10.64H159l-3.61,10.64H151l12.6-36h5.52l12.59,36Zm-10.79-32h-.26L160,65.9h12.44Z"
          fill="#f9f9f8"
        />
        <path
          d="M199.53,58.36l-4.34-8H195v30H190.8v-36h5l13.16,22,4.34,8h.15v-30h4.24v36h-5Z"
          fill="#f9f9f8"
        />
        <path
          d="M230.4,44.32h12.08c9,0,14.76,6,14.76,18s-5.78,18-14.76,18H230.4Zm12.08,32.21c5.93,0,10.11-4.13,10.11-11.25V59.39c0-7.12-4.18-11.25-10.11-11.25h-7.75V76.53Z"
          fill="#f9f9f8"
        />
        <path
          d="M323.94,28h-.35l-5.8,11.62L302.3,67.75l-15.15-28-6-12.53h-.34v53H264.49V.68h18.34L302.3,37.92h.23L321.77.68h18.45V80.17H323.94Z"
          fill="#de5c51"
        />
        <path
          d="M348.64.68h29.95c21.07,0,35,13.32,35,39.74s-13.89,39.75-35,39.75H348.64Zm29.95,64.11c10.25,0,16.63-5.58,16.63-18.22V34.27c0-12.64-6.38-18.22-16.63-18.22H366V64.79Z"
          fill="#de5c51"
        />
      </g>
    </g>
  </svg>
);

const CustomFooter = () => (
  <Footer links={links} Logo={Logo} Content={Content} />
);

export default CustomFooter;
