import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const IMAGE_QUERY = graphql`
  query MyQuery {
    file(name: { eq: "mountain-photo" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const MountainImage = () => {
  const { file } = useStaticQuery(IMAGE_QUERY);
  return (
    <Img
      alt="Dr. Rowland with his wife Sally in the mountains"
      fluid={file.childImageSharp.fluid}
    />
  );
};

export default MountainImage;
